$row-item-height: 1.85em;
$tictac-width: .69em;
$tictac-height: 1.35em;
$tictac-margin: 2px 2px 2px 2px;

button {
  cursor: pointer;
}

.Timer {
  .error {
    color: darkred;
  }
  .content {
    @media all and (max-width: 800px) {
      /* CSS rules here for screens lower than 750px */
      width: auto;
    }
    @media all and (min-width: 800px) {
      /* CSS rules here for screens above 750px */
      width: 50em;
    }

    height: 30em;
    position: relative;
    margin: auto;
    overflow-x: hidden;

    .left_column {
      border-radius: 3px;

      display: block;
      position: absolute;
      left: 1%;
      width: 29%;
      background-color: #ccc;
    }

    .right_column {
      border-radius: 3px;

      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      flex: 1 0 69%;
      margin-left: 30%;
      overflow-x: auto;
      background-color: #eee;
    }
  }

  .summary_cell {
    height: $row-item-height;
    display: grid;
    grid-template-columns: 100%;
  }

  .grid_ticks {
    display: grid;
    grid-template-columns: repeat(96, 18px);
  }

  .summary_header {
    height: $row-item-height;
    display: grid;
    grid-template-columns: 100%;
  }

  .tictac_header_row {
    height: $row-item-height;

    display: grid;
    grid-template-columns: repeat(24, 72px);
  }

  .tictac_cell {
    height: $row-item-height;
    background-color: lightblue;
  }

  .tictac_cell:nth-child(8n),
  .tictac_cell:nth-child(8n-1),
  .tictac_cell:nth-child(8n-2),
  .tictac_cell:nth-child(8n-3) {
    background-color: beige;
  }

  .tictac_empty,
  .tictac_distracted,
  .tictac_focused {
    cursor: pointer;
  }

  .tictac_empty {
    width: $tictac-width;
    height: $tictac-height;
    margin: $tictac-margin;
    border-radius: 3px;
    border: 2px solid darkgray;
  }

  .tictac_distracted {
    width: $tictac-width;
    height: $tictac-height;
    margin: $tictac-margin;
    border-radius: 3px;
    border:2px solid darkgray;
    position:relative;
  }
  .tictac_distracted:after{
    content:"";
    position:absolute;
    border-top:2px solid darkslategray;
    width:23px;
    left: 0px;
    transform: rotate(57deg);
    transform-origin: 0% 0%;
  }

  .tictac_focused {
    width: $tictac-width;
    height: $tictac-height;
    margin: $tictac-margin;
    border-radius: 3px;
    background-color: darkslategray;
    border: 2px solid darkgray;
  }

  .tictac_clicked {
    width: $tictac-width;
    height: $tictac-height;
    margin: $tictac-margin;
    border-radius: 3px;
    border: 2px solid darkgreen;
    animation: wiggle 1s linear infinite;
  }

}

$input-background: #f5f5f5;
$input-border: #c5c5c5;
$input-border-radius: 4px;
$input-font-size: 16px;
$input-font-color: #333;

input[type="text"] {
  flex: 1;

  margin: 2px;

  border: none;
  border-radius: $input-border-radius;
  background-color: $input-background;
  font-size: $input-font-size;
  color: $input-font-color;

  &::placeholder {
    color: #c5c5c5;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
  }
}

/* Keyframes */
@keyframes wiggle {
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
}
